let config = {
    url: `https://api.${process.env.REACT_APP_HOST}`,
    hostname: `${process.env.REACT_APP_HOST}`,
    api: {
        modem: '/api/modems',
        public: '/api/public',
        users: '/api/users',
        reports: '/api/reports',
        apiKeys: '/api/keys'
    }
}

export default config;