import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Sidebar, Sidenav, Nav } from 'rsuite';
import { isNull } from 'lodash';

//import icons
import UserBadgeIcon from '@rsuite/icons/UserBadge';
import GearIcon from '@rsuite/icons/Gear';
import SingleSource from '@rsuite/icons/SingleSource';
import BarChartIcon from '@rsuite/icons/BarChart';
import PeoplesIcon from '@rsuite/icons/Peoples';
import AppSelectIcon from '@rsuite/icons/AppSelect';

const NavLink = React.forwardRef(({ href, children, ...rest }, ref) => (
    <Link ref={ref} to={href} {...rest}>
      {children}
    </Link>
));

export default function SideMenu() {

    const [expanded, setExpanded] = useState(true);
    const [activeKey, setActiveKey] = useState('1');
    const [openKeys, setOpenKeys] = useState([]);

    const handleResetOpenSub = (openKeys) => {
        let activeSub;
        if(openKeys.length > 0){
            activeSub = openKeys.slice(-1);
        }else{
            activeSub = openKeys;
        }
        setOpenKeys(activeSub);
    }

    return( 
        <Sidebar style={{ display: 'flex', flexDirection: 'column' }} width={expanded ? 300 : 56} collapsible>
            <Sidenav expanded={expanded} openKeys={openKeys} onOpenChange={handleResetOpenSub}>
                <Sidenav.Body>
                <Nav activeKey={activeKey} onSelect={setActiveKey}>
                    <Nav.Item eventKey="1" icon={<UserBadgeIcon />} as={NavLink} href="/profile">Profile</Nav.Item>
                    <Nav.Item eventKey="2" icon={<SingleSource />} as={NavLink} href="/modems">Modems</Nav.Item>
                    <Nav.Item eventKey="3" icon={<PeoplesIcon />} as={NavLink} href="/users">Users</Nav.Item>
                    <Nav.Item eventKey="4" icon={<BarChartIcon />} as={NavLink} href="/statistic">Statistic</Nav.Item>
                    <Nav.Item eventKey="5" icon={<AppSelectIcon />} as={NavLink} href="/keys">Integrations</Nav.Item>
                    <Nav.Item eventKey="6" icon={<GearIcon />} as={NavLink} href="/parameters">Parameters</Nav.Item>
                </Nav>
                </Sidenav.Body>
                <Sidenav.Toggle expanded={expanded} onToggle={expanded => setExpanded(expanded)} />
            </Sidenav>
        </Sidebar>
    )
};