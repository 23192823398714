import React from 'react';
import { IconButton, Modal, Form, InputGroup, Notification } from 'rsuite';
import { isEmpty } from "lodash";

//import icons
import Close from '@rsuite/icons/legacy/Close';
import Lock from '@rsuite/icons/legacy/Lock';

import { changePassword } from '../../controllers/mainController';

class UserPasswordChange extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            password_repeat: ''
        };
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangePasswordRepeat = this.handleChangePasswordRepeat.bind(this);
        this.changePasswordInKC = this.changePasswordInKC.bind(this);
    }

    handleChangePassword = (value) => {
        this.setState({password: value});
    };

    handleChangePasswordRepeat = (value) => {
        this.setState({password_repeat: value});
    };

    changePasswordInKC = async() => {
        const toaster = this.props.toaster;

        try {

            let fields = {
                password: this.state.password
            }

            let request = await changePassword(fields);
            toaster.push(<Notification showIcon header={request.success === true ? 'Information' : 'Error'} type={request.success === true ? 'success' : 'error'}>{request.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
            
        } catch (err) {
            toaster.push(<Notification showIcon header={'Error'} type={'error'}>{err.response.data.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    render() {

        const handleCloseClick = () => {
            this.props.onClose();
        };

        const handleAcceptClick = () => {

            const toaster = this.props.toaster;

            if(isEmpty(this.state.password)){
                return(
                    toaster.push(<Notification showIcon header={'Error'} type={'error'}>Enter the password!</Notification>, {
                        duration: 10000,
                        placement: 'topEnd'
                    })
                )
            }

            if(isEmpty(this.state.password_repeat)){
                return(
                    toaster.push(<Notification showIcon header={'Error'} type={'error'}>Please, repeat password!</Notification>, {
                        duration: 10000,
                        placement: 'topEnd'
                    })
                )
            }

            if(this.state.password_repeat !== this.state.password){
                return(
                    toaster.push(<Notification showIcon header={'Error'} type={'error'}>Fields "password" and "Repeat password" must be same!</Notification>, {
                        duration: 10000,
                        placement: 'topEnd'
                    })
                )
            }

            let pass_exp = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
            if(pass_exp.test(this.state.password) === false){
                return (
                    toaster.push(<Notification showIcon header={'Error'} type={'error'}>Password must contain at least one number, one special symbol, latina characters and have a length between 8 of 16 symbols!</Notification>, {
                        duration: 10000,
                        placement: 'topEnd'
                    })
                )
            }

            this.changePasswordInKC();
            this.setState({password: '', password_repeat: ''});
            this.props.onClose();
        };

        return (
            <Modal backdrop="static" open={this.props.open} onClose={handleCloseClick} size="sm">
                <Modal.Header>
                    <Modal.Title>{this.props.caption}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form fluid>
                        <Form.Group controlId="password">
                            <Form.ControlLabel>Password*:</Form.ControlLabel>
                            <InputGroup>
                                <InputGroup.Addon><Lock/></InputGroup.Addon>
                                <Form.Control name="password" onChange={this.handleChangePassword} type="password" value={this.state.password}/>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="password_repeat">
                            <Form.ControlLabel>Repeat password*:</Form.ControlLabel>
                            <InputGroup>
                                <InputGroup.Addon><Lock/></InputGroup.Addon>
                                <Form.Control name="password_repeat" onChange={this.handleChangePasswordRepeat} type="password" value={this.state.password_repeat}/>
                            </InputGroup>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <IconButton icon={<Lock/>} appearance="primary" onClick={handleAcceptClick}>Change password</IconButton>
                    <IconButton icon={<Close />} appearance="subtle" onClick={handleCloseClick}>Cancel</IconButton>
                </Modal.Footer>
            </Modal> 
        );
    };
}

export default UserPasswordChange;