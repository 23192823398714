import React from 'react';
import { Container, Header, FlexboxGrid, Table, Checkbox, Pagination, Tooltip, Whisper, IconButton, ButtonGroup, Panel, Stack, Notification } from 'rsuite';
import { isEmpty, isNull } from "lodash";

//import icons
import SingleSource from '@rsuite/icons/SingleSource';
import CheckIcon from '@rsuite/icons/Check';
import CloseIcon from '@rsuite/icons/Close';
import TrashIcon from '@rsuite/icons/Trash';
import PlusIcon from '@rsuite/icons/Plus';
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import EmailIcon from '@rsuite/icons/Email';

//import common
import ConfirmModal from './elements/ConfirmModal';
import AddModemModal from './elements/AddModemModal';
import ModemSMSListModal from './elements/ModemSMSListModal';

import { getModems, getPhonesByModemId, getClient } from '../controllers/mainController';

const { Column, HeaderCell, Cell } = Table;

class ModemPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 100,
            page: 1,
            add_modem_modal: false,
            confirm_modal: false,
            confirm_modal_caption: '',
            confirm_modal_text: null,
            confirm_modal_buttonIcon: null,
            confirm_modal_buttonOkText: '',
            confirm_modal_onAction: null,
            confirm_modal_actionRequestBody: null,
            confirm_modal_color: "",
            modem_list: [],
            loading: false,
            sms_list_modal: false,
            modemId: null
        };
        this.confirmAction = this.confirmAction.bind(this);
        this.handleDeleteModemClick = this.handleDeleteModemClick.bind(this);
        this.deleteModem = this.deleteModem.bind(this);
        this.getAllModems = this.getAllModems.bind(this);
        this.setTableLoading = this.setTableLoading.bind(this);
        this.updateEntries = this.updateEntries.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleGetClientClick = this.handleGetClientClick.bind(this);
        this.handleCloseSMSModal = this.handleCloseSMSModal.bind(this);
    }

    componentDidMount() {
        this.updateEntries();
    }

    handleChangeLimit = dataKey => {
        this.setState({page: 1});
        this.setState({limit: dataKey});
        return;
    };

    handleChangePage = async(value) => {
        this.setState({ page: value });
        return;
    };

    handleDeleteModemClick = async(rowData) => {
        const toaster = this.props.toaster;

        let fields = {
            modemId: rowData.modemId
        }

        if(isNull((rowData.modemId))){
            return(
                toaster.push(<Notification showIcon header={'Error'} type={'error'}>Modem are not selected!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        this.confirmAction("red", "Delete modem", `Delete modem ${rowData.modemName}?`, <TrashIcon/>, "Delete", this.deleteModem, fields );
    }

    deleteModem = async(fields) => {
        console.log(fields);
    }

    updateEntries = async() => {
        this.getAllModems();
    }

    setTableLoading = async() => {

        this.setState({
            loading: true
        });

    }

    getAllModems = async() => {
        const toaster = this.props.toaster;

        try{

            this.setTableLoading();

            let modems = await getModems();

            let modem_list = modems.data;

            const promises = modem_list.map(async (item) => {
                const fetchWithTimeout = (modemId, timeout = 5000) => {
                    return Promise.race([
                        getPhonesByModemId(modemId),
                        new Promise((_, reject) =>
                            setTimeout(() => reject(new Error('Timeout')), timeout)
                        )
                    ]);
                };

                try {
                    const response = await fetchWithTimeout(item.modemId);                   
                    return { ...item, phones: response.data.List, status: true };
                } catch (error) {
                    return { ...item, phones: [], status: false };
                }
            });

            const results = await Promise.all(promises);

            this.setState({
                modem_list: results,
                loading: false
            })

        }catch(e){

            this.setState({
                modem_list: [],
                loading: false
            });

            toaster.push(<Notification showIcon header={'Error'} type={'error'}>Modems not found in database!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    confirmAction = async(color, caption, text, buttonIcon, buttonOkText, onAction, actionRequestBody) => {
        this.setState({
            confirm_modal: true, 
            confirm_modal_color: color, 
            confirm_modal_caption: caption, 
            confirm_modal_text: text, 
            confirm_modal_buttonIcon: buttonIcon, 
            confirm_modal_buttonOkText: buttonOkText, 
            confirm_modal_onAction: onAction, 
            confirm_modal_actionRequestBody: actionRequestBody
        });
    }

    handleCloseModal = async() => {

        this.setState({
            add_modem_modal: false,
        });

        this.getAllModems();

    }

    handleCloseSMSModal = async() => {

        this.setState({
            sms_list_modal: false,
        });

    }

    handleGetClientClick = async(modemId) => {

        const toaster = this.props.toaster;

        try{

           await getClient(modemId)

        }catch(e){
            toaster.push(<Notification showIcon header={'Error'} type={'error'}>Can't download client!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    render() {

        const sms_list_modal = this.state.sms_list_modal;
        const confirm_modal = this.state.confirm_modal;
        const add_modem_modal = this.state.add_modem_modal;
        let modems = this.state.modem_list;

        const data = modems.filter((v, i) => {
            const start = this.state.limit * (this.state.page - 1);
            const end = start + this.state.limit;
            return i >= start && i < end;
        });

        const SignCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                { rowData.active === false && <span style={{color: "#FF0000"}}><CloseIcon/></span> }
                { rowData.active === true && <span style={{color: "#50C878"}}><CheckIcon/></span> }
            </Cell>
        );

        const StatusCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                { rowData.status === false && <span style={{color: "#FF0000"}}>Lost</span> }
                { rowData.status === true && <span style={{color: "#50C878"}}>Established</span> }
            </Cell>
        );

        const PhonesCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                {(rowData.active && rowData.phones && rowData.phones.length > 0) ? (
                    rowData.phones.map((phoneObj, index) => {

                        if (!phoneObj.Phone) {
                            return null;
                        }

                        return (
                            <Whisper placement="top" trigger="hover" speaker={<Tooltip><span style={{display: "block"}}>Port: {phoneObj.Port}</span><span style={{display: "block"}}>CountryID: {phoneObj.CountryID}</span><span style={{display: "block"}}>IMSI: {phoneObj.IMSI}</span><span style={{display: "block"}}>ICCID: {phoneObj.ICCID}</span></Tooltip>}>
                                <span key={index}>
                                    {phoneObj.Phone}
                                    {index < rowData.phones.length - 1 && ', '}
                                </span>
                            </Whisper>
                        );
                    }).filter(Boolean)
                ) : (
                    'No Data'
                )}
            </Cell>
        );

        const ActionCell = ({ rowData, dataKey, ...props }) => {
            return (
                <Cell {...props}>                                               
                    <ButtonGroup>
                        <Whisper placement='top' speaker={<Tooltip>Download client</Tooltip>}>
                            <IconButton icon={<FileDownloadIcon/>} onClick={() => this.handleGetClientClick(rowData.modemId)}/>
                        </Whisper>
                        <Whisper placement='top' speaker={<Tooltip>SMS List</Tooltip>}>
                            <IconButton icon={<EmailIcon/>} onClick={() => this.setState({modemId: rowData.modemId, sms_list_modal: true })}/>
                        </Whisper>
                        <Whisper placement='top' speaker={<Tooltip>Delete</Tooltip>}>
                            <IconButton icon={<TrashIcon/>} onClick={() => this.handleDeleteModemClick(rowData)}/>
                        </Whisper>
                    </ButtonGroup>
                </Cell>
            );
        };

        return (
            <main>
                <Container>
                    <Header>
                        <FlexboxGrid justify="center">
                            <FlexboxGrid.Item colspan={24}>
                                <h2 className='header'><i><SingleSource/></i> Modems</h2>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Header>
                    <FlexboxGrid justify="center">
                        <div className='middle-flex-large'>
                            <FlexboxGrid.Item colspan={24}>
                                <Panel bordered style={{margin: "10px"}}>
                                    <Stack wrap spacing={10}>
                                        <IconButton icon={<PlusIcon />} onClick={() => this.setState({add_modem_modal: true})}>Add new</IconButton>
                                    </Stack>
                                </Panel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={24}>
                                <Table data={data} autoHeight={true} rowHeight={64} wordWrap="break-word" loading={this.state.loading} style={{margin: "15px auto 0 auto", padding: "0 15px"}}>
                                    <Column width={60} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell></HeaderCell>
                                        <Cell><Checkbox/></Cell>
                                    </Column>           
                                    <Column width={60} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>#</HeaderCell>
                                        <Cell dataKey="modemId" />
                                    </Column>
                                    <Column width={150} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Modem</HeaderCell>
                                        <Cell dataKey="modemName" />
                                    </Column>
                                    <Column width={180} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Username</HeaderCell>
                                        <Cell dataKey="username" />
                                    </Column>
                                    <Column width={60} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Password</HeaderCell>
                                        <Cell>***</Cell>
                                    </Column>
                                    <Column width={350} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Phones</HeaderCell>
                                        <PhonesCell dataKey="phones" />
                                    </Column>
                                    <Column width={80} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Active</HeaderCell>
                                        <SignCell dataKey="active" />
                                    </Column>
                                    <Column width={100} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Connection</HeaderCell>
                                        <StatusCell dataKey="status" />
                                    </Column>
                                    <Column flexGrow={1} align="center" verticalAlign="middle">
                                        <HeaderCell>...</HeaderCell>
                                        <ActionCell dataKey="modemId"/>
                                    </Column>
                                </Table>
                                <div style={{ padding: 20 }}>
                                    <Pagination prev next first last ellipsis boundaryLinks maxButtons={5} size="xs" layout={['total', '-', 'limit', '|', 'pager']} total={modems.length} limitOptions={[100, 300, 500]} limit={this.state.limit} activePage={this.state.page} onChangePage={this.handleChangePage} onChangeLimit={this.handleChangeLimit} style={{maxWidth: "auto", margin: "0 auto"}}/>
                                </div>
                            </FlexboxGrid.Item>
                        </div>
                    </FlexboxGrid>
                </Container>
                {confirm_modal && 
                    <ConfirmModal 
                        open={this.state.confirm_modal}
                        onClose={() => this.setState({ 
                            confirm_modal: false,
                            confirm_modal_caption: '',
                            confirm_modal_text: null,
                            confirm_modal_buttonIcon: null,
                            confirm_modal_buttonOkText: '',
                            confirm_modal_onAction: null,
                            confirm_modal_actionRequestBody: null,
                            confirm_modal_color: "",
                        })} 
                        color={this.state.confirm_modal_color} 
                        caption={this.state.confirm_modal_caption} 
                        text={this.state.confirm_modal_text} 
                        buttonIcon={this.state.confirm_modal_buttonIcon} 
                        buttonOkText={this.state.confirm_modal_buttonOkText} 
                        onAction={this.state.confirm_modal_onAction} 
                        actionRequestBody={this.state.confirm_modal_actionRequestBody}
                    />
                }
                {add_modem_modal && <AddModemModal open={this.state.add_modem_modal} onClose={() => this.handleCloseModal()} toaster={this.props.toaster}/>}
                {sms_list_modal && <ModemSMSListModal open={this.state.sms_list_modal} modemId={this.state.modemId} onClose={() => this.handleCloseSMSModal()} toaster={this.props.toaster}/>}
            </main>    
        );
    };
}

export default ModemPage;