import React from 'react';
import { Container, Header, FlexboxGrid, Table, Checkbox, Pagination, Notification } from 'rsuite';
import { isEmpty, isNull } from "lodash";
import { format } from 'date-fns';

//import icons
import BarChartIcon from '@rsuite/icons/BarChart';

import { getAllReports } from '../controllers/mainController';

const { Column, HeaderCell, Cell } = Table;

class StatisticPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 100,
            page: 1,
            report_list: [],
            loading: false
        };
        this.updateEntries = this.updateEntries.bind(this);
        this.getReports = this.getReports.bind(this);
        this.setTableLoading = this.setTableLoading.bind(this);
    }

    componentDidMount() {
        this.updateEntries();
    }

    updateEntries = async() => {
        this.getReports();
    }

    setTableLoading = async() => {

        this.setState({
            loading: true
        });

    }

    getReports = async() => {
        const toaster = this.props.toaster;

        try{

            this.setTableLoading();

            let reports = await getAllReports();

            this.setState({
                report_list: reports.data,
                loading: false
            })

        }catch(e){

            this.setState({
                report_list: [],
                loading: false
            });

            toaster.push(<Notification showIcon header={'Error'} type={'error'}>Reports not found in database!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    handleChangeLimit = dataKey => {
        this.setState({page: 1});
        this.setState({limit: dataKey});
        return;
    };

    handleChangePage = async(value) => {
        this.setState({ page: value });
        return;
    };

    render() {

        let reports = this.state.report_list;       

        const data = reports.filter((v, i) => {
            const start = this.state.limit * (this.state.page - 1);
            const end = start + this.state.limit;
            return i >= start && i < end;
        });

        const SignCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                { rowData.status === "error" && <span style={{color: "#8B0000"}}>error</span> }
                { rowData.status === "received" && <span style={{color: "#50C878"}}>received</span> }
            </Cell>
        );

        return (
            <main>
                <Container>
                    <Header>
                        <FlexboxGrid justify="center">
                            <FlexboxGrid.Item colspan={24}>
                                <h2 className='header'><i><BarChartIcon/></i> Statistic</h2>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Header>
                    <FlexboxGrid justify="center">
                        <div className='middle-flex-large'>
                            <FlexboxGrid.Item colspan={24}>
                                <Table data={data} autoHeight={true} rowHeight={64} wordWrap="break-word" loading={this.state.loading} style={{margin: "15px auto 0 auto", padding: "0 15px"}}>
                                    <Column width={60} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell></HeaderCell>
                                        <Cell><Checkbox/></Cell>
                                    </Column>           
                                    <Column width={60} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>#</HeaderCell>
                                        <Cell dataKey="reportId" />
                                    </Column>
                                    <Column width={80} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>userId</HeaderCell>
                                        <Cell dataKey="userId" />
                                    </Column>
                                    <Column width={100} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>smsId</HeaderCell>
                                        <Cell dataKey="smsId" />
                                    </Column>
                                    <Column width={100} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Phone</HeaderCell>
                                        <Cell dataKey="phone" />
                                    </Column>
                                    <Column width={80} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Status</HeaderCell>
                                        <SignCell dataKey="status" />
                                    </Column>
                                    <Column flexGrow={1} align="center" verticalAlign="middle">
                                        <HeaderCell>Text</HeaderCell>
                                        <Cell dataKey="text" />
                                    </Column>
                                </Table>
                                <div style={{ padding: 20 }}>
                                    <Pagination prev next first last ellipsis boundaryLinks maxButtons={5} size="xs" layout={['total', '-', 'limit', '|', 'pager']} total={reports.length} limitOptions={[100, 300, 500]} limit={this.state.limit} activePage={this.state.page} onChangePage={this.handleChangePage} onChangeLimit={this.handleChangeLimit} style={{maxWidth: "auto", margin: "0 auto"}}/>
                                </div>
                            </FlexboxGrid.Item>
                        </div>
                    </FlexboxGrid>
                </Container>
            </main>    
        );
    };
}

export default StatisticPage;