import React from 'react';
import { Content, Panel, FlexboxGrid } from 'rsuite';

import {getAdmin} from '../controllers/mainController';

class NotAuthPage extends React.Component {
    constructor(props) {
        super(props);
        this.checkAdmin = this.checkAdmin.bind(this);
    }

    componentDidMount() {
        this.checkAdmin();
    }

    checkAdmin = async() => {
        await getAdmin();
    }

    render() {

        return (
            <Content style={{display: 'flex'}}>
                <FlexboxGrid justify="center" align="middle" style={{flex: '1 0 auto', display: 'flex', flexDirection: 'column'}}>
                    <FlexboxGrid.Item colspan={12}>
                        <Panel header={<h3 style={{textAlign: 'center', width: "100%"}}>Access denied</h3>} style={{textAlign: 'center'}} bordered>
                            <span className='deniedSpan'>You are not authorized in the system. Please, log in.</span>
                        </Panel>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
        );
	}

}

export default NotAuthPage;