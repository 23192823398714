import React from 'react';
import { Container, Header, FlexboxGrid, Table, Checkbox, Pagination, Tooltip, Whisper, IconButton, ButtonGroup, Panel, Stack, Notification, Input } from 'rsuite';
import { isEmpty, isNull } from "lodash";

//import icons
import TrashIcon from '@rsuite/icons/Trash';
import PlusIcon from '@rsuite/icons/Plus';
import AppSelectIcon from '@rsuite/icons/AppSelect';
import Check from '@rsuite/icons/legacy/Check';

//import common
import ConfirmModal from './elements/ConfirmModal';

import { getApiKeys, deleteApiKeyById, addNewApiKey } from '../controllers/mainController';

const { Column, HeaderCell, Cell } = Table;

class IntegrationsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 100,
            page: 1,
            confirm_modal: false,
            confirm_modal_caption: '',
            confirm_modal_text: null,
            confirm_modal_buttonIcon: null,
            confirm_modal_buttonOkText: '',
            confirm_modal_onAction: null,
            confirm_modal_actionRequestBody: null,
            confirm_modal_color: "",
            keys_list: [],
            loading: false,
            keyId: null,
            name: ''
        };
        this.confirmAction = this.confirmAction.bind(this);
        this.handleDeleteApiKeyClick = this.handleDeleteApiKeyClick.bind(this);
        this.deleteApiKey = this.deleteApiKey.bind(this);
        this.getAllApiKeys = this.getAllApiKeys.bind(this);
        this.setTableLoading = this.setTableLoading.bind(this);
        this.updateEntries = this.updateEntries.bind(this);
        this.handleCreateApiKeyClick = this.handleCreateApiKeyClick.bind(this);
        this.createApiKey = this.createApiKey.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
    }

    componentDidMount() {
        this.updateEntries();
    }

    handleChangeLimit = dataKey => {
        this.setState({page: 1});
        this.setState({limit: dataKey});
        return;
    };

    handleChangePage = async(value) => {
        this.setState({ page: value });
        return;
    };

    handleChangeName = (value) => {
        this.setState({name: value});
    };

    handleCreateApiKeyClick = async() => {
        const toaster = this.props.toaster;

        let fields = {
            name: this.state.name
        }

        if(isEmpty(this.state.name) || isNull((this.state.name))){
            return(
                toaster.push(<Notification showIcon header={'Error'} type={'error'}>Name must be set!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        this.confirmAction("blue", "ApiKey creation", `Create apikey ${fields.name}?`, <Check/>, "Create", this.createApiKey, fields );

    }

    createApiKey = async(fields) => {
        const toaster = this.props.toaster;

        try{
            let request = await addNewApiKey(fields);

            toaster.push(<Notification showIcon header={'Information'} type={'success'}>{request.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
    
            this.setState(
                {
                    name: ''
                }
            );
        }catch(e){
            toaster.push(<Notification showIcon header={'Error'} type={'error'}>{e.response.data.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

        this.updateEntries();
    }

    handleDeleteApiKeyClick = async(rowData) => {
        const toaster = this.props.toaster;

        let fields = {
            keyId: rowData.keyId
        }

        if(isNull((rowData.keyId))){
            return(
                toaster.push(<Notification showIcon header={'Error'} type={'error'}>ApiKey are not selected!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        this.confirmAction("red", "Delete apikey", `Delete apikey ${rowData.name}[${rowData.apikey}]?`, <TrashIcon/>, "Delete", this.deleteApiKey, fields );
    }

    deleteApiKey = async(rowData) => {
        const toaster = this.props.toaster;

        try{
           
            let request = await deleteApiKeyById(rowData.keyId);

            toaster.push(<Notification showIcon header={'Information'} type={'success'}>{request.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });

        }catch(e){
            toaster.push(<Notification showIcon header={'Error'} type={'error'}>{e.response.data.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

        this.updateEntries();
    }

    updateEntries = async() => {
        this.getAllApiKeys();
    }

    setTableLoading = async() => {

        this.setState({
            loading: true
        });

    }

    getAllApiKeys = async() => {
        const toaster = this.props.toaster;

        try{

            this.setTableLoading();

            let apiKeys = await getApiKeys();

            this.setState({
                keys_list: apiKeys.data,
                loading: false
            })

        }catch(e){

            this.setState({
                keys_list: [],
                loading: false
            });

            toaster.push(<Notification showIcon header={'Error'} type={'error'}>ApiKeys not found in database!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    confirmAction = async(color, caption, text, buttonIcon, buttonOkText, onAction, actionRequestBody) => {
        this.setState({
            confirm_modal: true, 
            confirm_modal_color: color, 
            confirm_modal_caption: caption, 
            confirm_modal_text: text, 
            confirm_modal_buttonIcon: buttonIcon, 
            confirm_modal_buttonOkText: buttonOkText, 
            confirm_modal_onAction: onAction, 
            confirm_modal_actionRequestBody: actionRequestBody
        });
    }

    render() {

        const confirm_modal = this.state.confirm_modal;
        let keys = this.state.keys_list;

        const data = keys.filter((v, i) => {
            const start = this.state.limit * (this.state.page - 1);
            const end = start + this.state.limit;
            return i >= start && i < end;
        });

        const ActionCell = ({ rowData, dataKey, ...props }) => {
            return (
                <Cell {...props}>                                               
                    <ButtonGroup>
                        <Whisper placement='top' speaker={<Tooltip>Delete</Tooltip>}>
                            <IconButton icon={<TrashIcon/>} onClick={() => this.handleDeleteApiKeyClick(rowData)}/>
                        </Whisper>
                    </ButtonGroup>
                </Cell>
            );
        };

        return (
            <main>
                <Container>
                    <Header>
                        <FlexboxGrid justify="center">
                            <FlexboxGrid.Item colspan={24}>
                                <h2 className='header'><i><AppSelectIcon/></i> API Keys</h2>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Header>
                    <FlexboxGrid justify="center">
                        <div className='middle-flex-large'>
                            <FlexboxGrid.Item colspan={24}>
                                <Panel bordered style={{margin: "10px"}}>
                                    <Stack wrap spacing={10}>
                                        <Input onChange={this.handleChangeName} value={this.state.name}/>
                                        <IconButton icon={<PlusIcon />} onClick={this.handleCreateApiKeyClick}>Generate</IconButton>
                                    </Stack>
                                </Panel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={24}>
                                <Table data={data} autoHeight={true} rowHeight={64} wordWrap="break-word" loading={this.state.loading} style={{margin: "15px auto 0 auto", padding: "0 15px"}}>
                                    <Column width={60} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell></HeaderCell>
                                        <Cell><Checkbox/></Cell>
                                    </Column>           
                                    <Column width={60} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>#</HeaderCell>
                                        <Cell dataKey="keyId" />
                                    </Column>
                                    <Column width={150} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Name</HeaderCell>
                                        <Cell dataKey="name" />
                                    </Column>
                                    <Column width={300} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Key</HeaderCell>
                                        <Cell dataKey="apikey" />
                                    </Column>
                                    <Column flexGrow={1} align="center" verticalAlign="middle">
                                        <HeaderCell>...</HeaderCell>
                                        <ActionCell dataKey="keyId"/>
                                    </Column>
                                </Table>
                                <div style={{ padding: 20 }}>
                                    <Pagination prev next first last ellipsis boundaryLinks maxButtons={5} size="xs" layout={['total', '-', 'limit', '|', 'pager']} total={keys.length} limitOptions={[100, 300, 500]} limit={this.state.limit} activePage={this.state.page} onChangePage={this.handleChangePage} onChangeLimit={this.handleChangeLimit} style={{maxWidth: "auto", margin: "0 auto"}}/>
                                </div>
                            </FlexboxGrid.Item>
                        </div>
                    </FlexboxGrid>
                </Container>
                {confirm_modal && 
                    <ConfirmModal 
                        open={this.state.confirm_modal}
                        onClose={() => this.setState({ 
                            confirm_modal: false,
                            confirm_modal_caption: '',
                            confirm_modal_text: null,
                            confirm_modal_buttonIcon: null,
                            confirm_modal_buttonOkText: '',
                            confirm_modal_onAction: null,
                            confirm_modal_actionRequestBody: null,
                            confirm_modal_color: "",
                        })} 
                        color={this.state.confirm_modal_color} 
                        caption={this.state.confirm_modal_caption} 
                        text={this.state.confirm_modal_text} 
                        buttonIcon={this.state.confirm_modal_buttonIcon} 
                        buttonOkText={this.state.confirm_modal_buttonOkText} 
                        onAction={this.state.confirm_modal_onAction} 
                        actionRequestBody={this.state.confirm_modal_actionRequestBody}
                    />
                }
            </main>    
        );
    };
}

export default IntegrationsPage;