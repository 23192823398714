import axios from 'axios';
import config from '../config';
import fileDownload from 'js-file-download';

export async function createUser (fields) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.users + '/register', fields).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function changePassword (fields) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.users + '/password', fields).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function addNewModem (fields) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.modem + '/new', fields).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function makeACall (modemId, fields) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.modem + '/' + modemId + '/phones/call', fields).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function sendSmsByModemId (modemId, fields) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.modem + '/' + modemId + '/sms/send', fields).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function sendUssdCode (modemId, smsId, fields) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.modem + '/' + modemId + '/sms/' + smsId + '/ussd', fields).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getAllUsers () {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.users + '/').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getUserInfo (userId) {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.users + '/info').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getModems () {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.modem + '/').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getSmsStatusBySmsId (modemId, smsId) {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.modem + '/' + modemId + '/sms/' + smsId + '/status').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getSmsTextBySmsStartId (modemId, smsId) {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.modem + '/' + modemId + '/sms/' + smsId + '/text').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getPhonesByModemId (modemId) {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.modem + '/' + modemId + '/phones').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getClient (modemId) {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.modem + '/' + modemId + '/client', { responseType: "blob" }).then(async (response)=>{
            fileDownload(response.data, "client.zip")
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function updateUserById (userId, fields) {
    return await new Promise((resolve, reject)=>{
        axios.put(config.url + config.api.users + '/' + userId + '/update', fields).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getAllReports () {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.reports + '/').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function addNewReport (fields) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.reports + '/new', fields).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getAdmin () {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.public + '/admin').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function deleteUserById (userId) {
    return await new Promise((resolve, reject)=>{
        axios.delete(config.url + config.api.users + '/' + userId + '/delete').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getApiKeys () {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.apiKeys + '/').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function addNewApiKey (fields) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.apiKeys + '/new', fields).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function deleteApiKeyById (keyId) {
    return await new Promise((resolve, reject)=>{
        axios.delete(config.url + config.api.apiKeys + '/' + keyId + '/delete').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

//router.delete  modem
