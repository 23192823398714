import React from 'react';
import { Modal, Checkbox, Table, IconButton, Pagination, Notification } from 'rsuite';

import CloseIcon from '@rsuite/icons/Close';

import { getSmsTextBySmsStartId, getSmsStatusBySmsId, sendSmsByModemId } from '../../controllers/mainController';

const { Column, HeaderCell, Cell } = Table;

class ModemSMSListModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 10,
            page: 1,
            sms_list: [],
            loading: false
        };
        this.handleChangeLimit = this.handleChangeLimit.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.getSMS = this.getSMS.bind(this);
        this.updateEntries = this.updateEntries.bind(this);
        this.setTableLoading = this.setTableLoading.bind(this);
        this.intervalId = null;
    }

    componentDidMount() {
        this.updateEntries();

        this.intervalId = setInterval(this.updateEntries, 30000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    updateEntries = async() => {
        this.getSMS();
    }

    setTableLoading = async() => {

        this.setState({
            loading: true
        });

    }

    getSMS = async() => {
        const toaster = this.props.toaster;

        try{

            this.setTableLoading();

            let startId = 1;

            let sms = await getSmsTextBySmsStartId(this.props.modemId, startId);
            console.log(sms);
            

            this.setState({
                sms_list: sms.data.List,
                loading: false
            })

        }catch(e){

            this.setState({
                sms_list: [],
                loading: false
            });

        }
    }

    handleChangeLimit = dataKey => {
        this.setState({page: 1});
        this.setState({limit: dataKey});
        return;
    };

    handleChangePage = async(value) => {
        this.setState({ page: value });
        return;
    };
    
    render() {

        let sms = this.state.sms_list;

        const handleCloseClick = () => {
            this.props.onClose();
        };

        const data = sms.filter((v, i) => {
            const start = this.state.limit * (this.state.page - 1);
            const end = start + this.state.limit;
            return i >= start && i < end;
        });

        return (
            <Modal backdrop="static" open={this.props.open} onClose={handleCloseClick} size="lg">
                <Modal.Title>SMS Log</Modal.Title>
                <Modal.Body>
                    <Table data={data} autoHeight={true} rowHeight={64} wordWrap="break-word" loading={this.state.loading} style={{margin: "15px auto 0 auto", padding: "0 15px"}}>           
                        <Column width={60} align="center" verticalAlign="middle" fixed>
                            <HeaderCell>#</HeaderCell>
                            <Cell dataKey="ID" />
                        </Column>
                        <Column width={100} align="center" verticalAlign="middle" fixed>
                            <HeaderCell>Time</HeaderCell>
                            <Cell dataKey="Time" />
                        </Column>
                        <Column width={60} align="center" verticalAlign="middle" fixed>
                            <HeaderCell>Port</HeaderCell>
                            <Cell dataKey="Port" />
                        </Column>
                        <Column width={90} align="center" verticalAlign="middle" fixed>
                            <HeaderCell>Phone</HeaderCell>
                            <Cell dataKey="Phone" />
                        </Column>
                        <Column width={90} align="center" verticalAlign="middle" fixed>
                            <HeaderCell>From</HeaderCell>
                            <Cell dataKey="From" />
                        </Column>
                        <Column flexGrow={1} align="center" verticalAlign="middle" fixed>
                            <HeaderCell>Message</HeaderCell>
                            <Cell dataKey="Message" />
                        </Column>
                    </Table>
                    <div style={{ padding: 20 }}>
                        <Pagination prev next first last ellipsis boundaryLinks maxButtons={5} size="xs" layout={['total', '-', 'limit', '|', 'pager']} total={sms.length} limitOptions={[100, 300, 500]} limit={this.state.limit} activePage={this.state.page} onChangePage={this.handleChangePage} onChangeLimit={this.handleChangeLimit} style={{maxWidth: "auto", margin: "0 auto"}}/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <IconButton icon={<CloseIcon/>} onClick={handleCloseClick}>Close</IconButton>
                </Modal.Footer>
            </Modal> 
        );
    };
}

export default ModemSMSListModal;