import React from 'react';
import { Container, Header, FlexboxGrid, Panel, ButtonToolbar, Button} from 'rsuite';

//import icons
import PeoplesIcon from '@rsuite/icons/Peoples';
import Lock from '@rsuite/icons/legacy/Lock';

import UserPasswordChange from './elements/UserPasswordChange';

import { getUserInfo } from '../controllers/mainController';

class ProfilePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            lastname: '',
            firstname: '',
            email: '',
            phone: '',
            userId: '',
            password_modal: false
        };
        this.getProfileData = this.getProfileData.bind(this);
    }

    getProfileData = async() => {
        let userInfo = await getUserInfo();
        console.log(userInfo);
        
        this.setState({
            username: userInfo.data.username,
            lastname: userInfo.data.lastname,
            firstname: userInfo.data.firstname,
            email: userInfo.data.email,
            phone: userInfo.data.phone,
            userId: userInfo.data.userExternalId,
        })
    }

    componentDidMount() {
        this.getProfileData();
    }

    render() {

        const password_modal = this.state.password_modal;

        return (
            <main>
                <Container>
                    <Header>
                        <FlexboxGrid justify="center">
                            <FlexboxGrid.Item colspan={24}>
                                <h2 className='header'><i><PeoplesIcon/></i> Profile</h2>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Header>
                    <FlexboxGrid justify="center">
                        <FlexboxGrid.Item colspan={24}>
                            <div className='middle-flex-large'>
                                <FlexboxGrid justify="center" style={{alignContent: "stretch", alignItems: "stretch"}}>
                                    <FlexboxGrid.Item colspan={12}>
                                        <Panel header="Information" bordered style={{margin: "10px"}}>
                                            <span className='profileInfo'><b>ID:</b> {this.state.userId}</span>
                                            <span className='profileInfo'><b>Username:</b> {this.state.username}</span>
                                            <span className='profileInfo'><b>Firstname:</b> {this.state.firstname}</span>
                                            <span className='profileInfo'><b>Lastname:</b> {this.state.lastname}</span>
                                            <span className='profileInfo'><b>Email:</b> {this.state.email}</span>
                                            <span className='profileInfo'><b>Phone:</b> <a style={{textDecoration: "none"}} href={`tel:${this.state.phone}`}>{this.state.phone}</a></span>
                                            <div className='lowPadding'>
                                                <ButtonToolbar>
                                                        <Button appearance='ghost'><Lock /><span style={{marginLeft: "7px"}} onClick={() => this.setState({password_modal: true})}>Change password</span></Button>
                                                </ButtonToolbar>
                                            </div>
                                        </Panel>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={12}>
                                        <Panel header="Statistic" bordered style={{margin: "10px"}}>
                                        </Panel>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </div>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Container>
                {password_modal && <UserPasswordChange open={this.state.password_modal} onClose={() => this.setState({ password_modal: false })} caption={"Change password"} toaster={this.props.toaster}/>}
            </main>    
        );
    };
}

export default ProfilePage;