import React from 'react';
import { IconButton, Modal, Form, InputGroup, Checkbox, Notification } from 'rsuite';
import { isEmpty, isNull } from 'lodash';

//import icons
import CheckIcon from '@rsuite/icons/Check';
import CloseIcon from '@rsuite/icons/Close';
import Lock from '@rsuite/icons/legacy/Lock';
import OperatePeopleIcon from '@rsuite/icons/OperatePeople';
import ParagraphIcon from '@rsuite/icons/Paragraph';

import { addNewModem } from '../../controllers/mainController';

class AddModemModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            username: '',
            password: '',
            modemName: ''
        };
        this.createModem = this.createModem.bind(this);
        this.handleChangeUsername = this.handleChangeUsername.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangeModemName = this.handleChangeModemName.bind(this);
    }

    handleChangeUsername = (value) => {
        this.setState({username: value});
    };

    handleChangePassword = (value) => {
        this.setState({password: value});
    };

    handleChangeModemName = (value) => {
        this.setState({modemName: value});
    };

    createModem = async() => {

        const toaster = this.props.toaster;

        try{

            let fields = {
                username: this.state.username,
                password: this.state.password,
                modemName: this.state.modemName,
                active: this.state.active
            }

            if(isEmpty(this.state.username) || isNull((this.state.username))){
                return(
                    toaster.push(<Notification showIcon header={'Error'} type={'error'}>Username must be set!</Notification>, {
                        duration: 10000,
                        placement: 'topEnd'
                    })
                )
            }
    
            if(isEmpty(this.state.password) || isNull((this.state.password))){
                return(
                    toaster.push(<Notification showIcon header={'Error'} type={'error'}>Password must be set!</Notification>, {
                        duration: 10000,
                        placement: 'topEnd'
                    })
                )
            }

            if(isEmpty(this.state.modemName) || isNull((this.state.modemName))){
                return(
                    toaster.push(<Notification showIcon header={'Error'} type={'error'}>modemName must be set!</Notification>, {
                        duration: 10000,
                        placement: 'topEnd'
                    })
                )
            }

            let request = await addNewModem(fields);

            toaster.push(<Notification showIcon header={'Information'} type={'success'}>{request.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
    
            this.setState(
                {
                    active: false,
                    username: '',
                    password: '',
                    modemName: '',
                }
            );

            this.props.onClose();

        }catch(e){
            toaster.push(<Notification showIcon header={'Error'} type={'error'}>{e.response.data.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    render() {

        const handleCloseClick = () => {
            this.setState(
                {
                    active: false,
                    username: '',
                    password: '',
                    modemName: ''
                }
            );
            this.props.onClose();
        };

        return (
            <Modal backdrop="static" open={this.props.open} onClose={handleCloseClick} size="xs">
                <Modal.Title>Add new modem</Modal.Title>
                <Modal.Body>
                    <Form fluid>
                        <Form.Group controlId="modemName">
                            <Form.ControlLabel>Modem Name</Form.ControlLabel>
                            <InputGroup>
                                <InputGroup.Addon><ParagraphIcon/></InputGroup.Addon>
                                <Form.Control name="modemName" placeholder="China pool" onChange={this.handleChangeModemName} value={this.state.modemName}/>
                            </InputGroup>
                            <Form.HelpText>Enter modem's name (latina symbols)</Form.HelpText>
                        </Form.Group>
                        <Form.Group controlId="username">
                            <Form.ControlLabel>Username</Form.ControlLabel>
                            <InputGroup>
                                <InputGroup.Addon><OperatePeopleIcon/></InputGroup.Addon>
                                <Form.Control name="username" placeholder="johndoe" onChange={this.handleChangeUsername} value={this.state.username}/>
                            </InputGroup>
                            <Form.HelpText>Enter username for firefox panel.</Form.HelpText>
                        </Form.Group>
                        <Form.Group controlId="password">
                            <Form.ControlLabel>Password</Form.ControlLabel>
                            <InputGroup>
                                <InputGroup.Addon><Lock/></InputGroup.Addon>
                                <Form.Control name="password" type="password" autocomplete="off" onChange={this.handleChangePassword} value={this.state.password}/>
                            </InputGroup>
                            <Form.HelpText>Enter password for firefox panel.</Form.HelpText>
                        </Form.Group>
                        <Form.Group controlId="active">
                            <Checkbox onChange={() => this.setState({active: !this.state.active})} checked={this.state.active}>Active</Checkbox>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <IconButton icon={<CheckIcon />} onClick={this.createModem}>Add</IconButton>
                    <IconButton icon={<CloseIcon/>} onClick={handleCloseClick}>Close</IconButton>
                </Modal.Footer>
            </Modal> 
        );
    };
}

export default AddModemModal;