import React from 'react';
import { Container, Header, Panel, FlexboxGrid, Table, Dropdown, Popover, Whisper, IconButton, Form, ButtonToolbar, InputGroup, Pagination, Notification } from 'rsuite';
import { isEmpty, isNull } from "lodash";

//import icons
import PeoplesIcon from '@rsuite/icons/Peoples';
import MoreIcon from '@rsuite/icons/More';
import UserInfoIcon from '@rsuite/icons/UserInfo';
import OperatePeopleIcon from '@rsuite/icons/OperatePeople';
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';
import CheckOutlineIcon from '@rsuite/icons/CheckOutline';
import CloseOutlineIcon from '@rsuite/icons/CloseOutline';
import ParagraphIcon from '@rsuite/icons/Paragraph';
import Lock from '@rsuite/icons/legacy/Lock';
import Check from '@rsuite/icons/legacy/Check';
import Phone from '@rsuite/icons/legacy/Phone';

//import common
import ConfirmModal from './elements/ConfirmModal';

import { createUser, getAllUsers, deleteUserById } from '../controllers/mainController';

const { Column, HeaderCell, Cell } = Table;

class UsersPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 10,
            page: 1,
            confirm_modal: false,
            confirm_modal_caption: '',
            confirm_modal_text: null,
            confirm_modal_buttonIcon: null,
            confirm_modal_buttonOkText: '',
            confirm_modal_onAction: null,
            confirm_modal_actionRequestBody: null,
            confirm_modal_color: "",
            userId: null,
            user_list: [],
            username: '',
            password: '',
            firstname: '',
            lastname: '',
            phone: '',
            email: '',
            loading: false
        };
        this.handleChangeLimit = this.handleChangeLimit.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.confirmAction = this.confirmAction.bind(this);
        this.handleDeleteUserClick = this.handleDeleteUserClick.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.updateEntries = this.updateEntries.bind(this);
        this.handleChangeUsername = this.handleChangeUsername.bind(this);
        this.handleChangeFirstname = this.handleChangeFirstname.bind(this);
        this.handleChangeLastname = this.handleChangeLastname.bind(this);
        this.handleChangeMail = this.handleChangeMail.bind(this);
        this.handleChangePhone = this.handleChangePhone.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.createUser = this.createUser.bind(this);
        this.handleResetFields = this.handleResetFields.bind(this);
        this.handleCreateUserClick = this.handleCreateUserClick.bind(this);
        this.setTableLoading = this.setTableLoading.bind(this);
    }

    handleCreateUserClick = async() => {
        const toaster = this.props.toaster;

        let fields = {
            username: this.state.username,
            password: this.state.password,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: this.state.email,
            phone: this.state.phone
        }

        if(isEmpty(this.state.username) || isNull((this.state.username))){
            return(
                toaster.push(<Notification showIcon header={'Error'} type={'error'}>Username must be set!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        if(isEmpty(this.state.password) || isNull((this.state.password))){
            return(
                toaster.push(<Notification showIcon header={'Error'} type={'error'}>Password must be set!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        if(isEmpty(this.state.phone) || isNull(this.state.phone)){
            return (
                toaster.push(<Notification showIcon header={'Error'} type={'error'}>Phone must be set!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        this.confirmAction("blue", "User creation", `Create user ${fields.username}?`, <Check/>, "Create", this.createUser, fields );

    }

    createUser = async(fields) => {
        const toaster = this.props.toaster;

        try{
            let request = await createUser(fields);

            toaster.push(<Notification showIcon header={'Information'} type={'success'}>{request.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
    
            this.setState(
                {
                    userId: null,
                    username: '',
                    password: '',
                    firstname: '',
                    lastname: '',
                    telegram: '',
                    phone: '',
                }
            );
        }catch(e){
            toaster.push(<Notification showIcon header={'Error'} type={'error'}>{e.response.data.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

        this.updateEntries();
    }

    handleChangeUsername = (value) => {
        this.setState({username: value});
    };

    handleChangeMail = (value) => {
        this.setState({email: value});
    };

    handleChangeFirstname = (value) => {
        this.setState({firstname: value});
    };

    handleChangeLastname = (value) => {
        this.setState({lastname: value});
    };

    handleChangePhone = (value) => {
        this.setState({phone: value});
    };

    handleChangePassword = (value) => {
        this.setState({password: value});
    };

    handleResetFields = () => {
        const toaster = this.props.toaster;
        this.setState(
            {
                userId: null,
                username: '',
                password: '',
                firstname: '',
                lastname: '',
                email: '',
                phone: ''
            }
        );
        toaster.push(<Notification showIcon header={'Information'} type={'success'}>All fields cleared!</Notification>, {
            duration: 10000,
            placement: 'topEnd'
        });
    };

    componentDidMount() {
        this.updateEntries();
    }

    updateEntries = async() => {
        this.getUsers();
    }

    setTableLoading = async() => {

        this.setState({
            loading: true
        });

    }

    getUsers = async() => {
        const toaster = this.props.toaster;

        try{

            this.setTableLoading();

            let users = await getAllUsers();

            this.setState({
                user_list: users.data,
                loading: false
            })

        }catch(e){

            this.setState({
                user_list: [],
                loading: false
            });

            toaster.push(<Notification showIcon header={'Error'} type={'error'}>Users not found in database!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    handleDeleteUserClick = async(rowData) => {
        const toaster = this.props.toaster;

        if(isNull((rowData.userId))){
            return(
                toaster.push(<Notification showIcon header={'Error'} type={'error'}>User are not selected!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        this.confirmAction("red", "Delete user", `Delete user ${rowData.username}?`, <TrashIcon/>, "Delete", this.deleteUser, rowData.userId );
    }

    handleChangeLimit = dataKey => {
        this.setState({page: 1});
        this.setState({limit: dataKey});
        return;
    };

    handleChangePage = async(value) => {
        this.setState({ page: value });
        return;
    };

    confirmAction = async(color, caption, text, buttonIcon, buttonOkText, onAction, actionRequestBody) => {
        this.setState({
            confirm_modal: true, 
            confirm_modal_color: color, 
            confirm_modal_caption: caption, 
            confirm_modal_text: text, 
            confirm_modal_buttonIcon: buttonIcon, 
            confirm_modal_buttonOkText: buttonOkText, 
            confirm_modal_onAction: onAction, 
            confirm_modal_actionRequestBody: actionRequestBody
        });
    }

    deleteUser = async(userId) => {
        const toaster = this.props.toaster;

        try{
            console.log(userId);
            
            let request = await deleteUserById(userId);

            toaster.push(<Notification showIcon header={'Information'} type={'success'}>{request.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });

        }catch(e){
            toaster.push(<Notification showIcon header={'Error'} type={'error'}>{e.response.data.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

        this.updateEntries();
    }
    
    render() {

        const confirm_modal = this.state.confirm_modal;
        let users = this.state.user_list;
              
        const ActionCell = ({ rowData, dataKey, ...props }) => {
            return (
              <Cell {...props} className="link-group">
                <Whisper 
                    placement="autoVerticalStart"
                    trigger="click" 
                    speaker={(props, ref) => {
                        const { onClose, left, top, className } = props;
                        const handleSelect = eventKey => {
                            if (eventKey === 1){
                                
                            }
                            if (eventKey === 2){
                                
                            }
                            if (eventKey === 3){
                                this.handleDeleteUserClick(rowData);
                            }
                            onClose();
                        };
                        return (
                            <Popover ref={ref} className={className} style={{ left, top }} full>
                                <Dropdown.Menu onSelect={handleSelect}>
                                    <Dropdown.Item eventKey={1} icon={<UserInfoIcon />} >User's information</Dropdown.Item>
                                    <Dropdown.Item divider />
                                    <Dropdown.Item eventKey={2} icon={<EditIcon />} >Edit</Dropdown.Item>
                                    <Dropdown.Item eventKey={3} icon={<TrashIcon />} >Delete</Dropdown.Item>
                                </Dropdown.Menu>
                            </Popover>
                        )
                    }}
                >
                  <IconButton appearance="subtle" icon={<MoreIcon />} />
                </Whisper>
              </Cell>
            );
        };

        const data = users.filter((v, i) => {
            const start = this.state.limit * (this.state.page - 1);
            const end = start + this.state.limit;
            return i >= start && i < end;
        });

        return (
            <main>
                <Container>
                    <Header>
                        <FlexboxGrid justify="center">
                            <FlexboxGrid.Item colspan={24}>
                                <h2 className='header'><i><PeoplesIcon/></i> Users</h2>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Header>
                    <FlexboxGrid justify="center">
                        <FlexboxGrid.Item colspan={24}>
                            <div className='middle-flex-large'>
                                <FlexboxGrid justify="center" style={{alignContent: "stretch", alignItems: "stretch"}}>
                                    <FlexboxGrid.Item colspan={12}>
                                        <Panel bordered style={{margin: "10px"}}>
                                            <Form fluid>
                                                <Form.Group controlId="username">
                                                    <Form.ControlLabel>Username*:</Form.ControlLabel>
                                                    <InputGroup>
                                                        <InputGroup.Addon><OperatePeopleIcon/></InputGroup.Addon>
                                                        <Form.Control name="username" onChange={this.handleChangeUsername} value={this.state.username}/>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group controlId="firstname">
                                                    <Form.ControlLabel>Firstname:</Form.ControlLabel>
                                                    <InputGroup>
                                                        <InputGroup.Addon><ParagraphIcon/></InputGroup.Addon>
                                                        <Form.Control name="firstname" onChange={this.handleChangeFirstname} value={this.state.firstname}/>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group controlId="Lastname">
                                                    <Form.ControlLabel>Lastname:</Form.ControlLabel>
                                                    <InputGroup>
                                                        <InputGroup.Addon><ParagraphIcon/></InputGroup.Addon>
                                                        <Form.Control name="Lastname" onChange={this.handleChangeLastname} value={this.state.lastname}/>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group controlId="phone">
                                                    <Form.ControlLabel>Phone*</Form.ControlLabel>
                                                    <InputGroup>
                                                        <InputGroup.Addon><Phone/></InputGroup.Addon>
                                                        <Form.Control name="phone" onChange={this.handleChangePhone} value={this.state.phone}/>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group controlId="email">
                                                    <Form.ControlLabel>Email:</Form.ControlLabel>
                                                    <InputGroup>
                                                        <InputGroup.Addon>@</InputGroup.Addon>
                                                        <Form.Control name="email" onChange={this.handleChangeMail} type="email" value={this.state.email}/>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group controlId="password">
                                                    <Form.ControlLabel>Password*:</Form.ControlLabel>
                                                    <InputGroup>
                                                        <InputGroup.Addon><Lock/></InputGroup.Addon>
                                                        <Form.Control name="password" type="password" onChange={this.handleChangePassword} value={this.state.password}/>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group>
                                                    <ButtonToolbar>
                                                        <IconButton icon={<CheckOutlineIcon />} onClick={this.handleCreateUserClick}> Create</IconButton>        
                                                        <IconButton icon={<CloseOutlineIcon />} onClick={this.handleResetFields}> Reset</IconButton>
                                                    </ButtonToolbar>
                                                </Form.Group>
                                            </Form>
                                        </Panel>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={12}>
                                        <Panel bordered style={{margin: "10px"}}>
                                            <Table data={data} height={560} rowHeight={64} wordWrap="break-word" loading={this.state.loading} virtualized>

                                                <Column width={60} align="center" verticalAlign="middle">
                                                    <HeaderCell>ID</HeaderCell>
                                                    <Cell dataKey="userId" />
                                                </Column>

                                                <Column width={100} align="center" verticalAlign="middle">
                                                    <HeaderCell>Username</HeaderCell>
                                                    <Cell dataKey="username" />
                                                </Column>

                                                <Column width={120} align="center" verticalAlign="middle">
                                                    <HeaderCell>Phone</HeaderCell>
                                                    <Cell dataKey="phone" />
                                                </Column>

                                                <Column width={120} align="center" verticalAlign="middle">
                                                    <HeaderCell>Email</HeaderCell>
                                                    <Cell dataKey="email" />
                                                </Column>

                                                <Column flexGrow={1} align="center" verticalAlign="middle">
                                                    <HeaderCell>...</HeaderCell>
                                                    <ActionCell dataKey="userId" />
                                                </Column>
                                            </Table>
                                            <div style={{ padding: 20 }}>
                                                <Pagination prev next first last ellipsis boundaryLinks maxButtons={5} size="xs" layout={['total', '-', 'limit', '|', 'pager']} total={users.length} limitOptions={[10, 30, 50]} limit={this.state.limit} activePage={this.state.page} onChangePage={this.handleChangePage} onChangeLimit={this.handleChangeLimit}/>
                                            </div>
                                        </Panel>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </div>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Container>
                {confirm_modal && 
                    <ConfirmModal 
                        open={this.state.confirm_modal}
                        onClose={() => this.setState({ 
                            confirm_modal: false,
                            confirm_modal_caption: '',
                            confirm_modal_text: null,
                            confirm_modal_buttonIcon: null,
                            confirm_modal_buttonOkText: '',
                            confirm_modal_onAction: null,
                            confirm_modal_actionRequestBody: null,
                            confirm_modal_color: "",
                        })} 
                        color={this.state.confirm_modal_color} 
                        caption={this.state.confirm_modal_caption} 
                        text={this.state.confirm_modal_text} 
                        buttonIcon={this.state.confirm_modal_buttonIcon} 
                        buttonOkText={this.state.confirm_modal_buttonOkText} 
                        onAction={this.state.confirm_modal_onAction} 
                        actionRequestBody={this.state.confirm_modal_actionRequestBody}
                    />
                }
            </main>    
        );
    };
}

export default UsersPage;